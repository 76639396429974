import { useCallback, useState } from 'react';
import { useOnlineBookingLogout } from '../components/OnlineBooking/OnlineBooking';
import { useOrdersLogout } from '../components/Orders/Orders';

export const LogoutTools = {
    /**
     * Clears local and session storage of the browser.
     *
     * TODO: maybe we should erase the storages selectively, for example keeping `i18nextLng` in localStorage
     */
    clearBrowserStorage: () => {
        sessionStorage.clear();
        localStorage.clear();
    },
    /**
     * @param statusMessage - translation key for backend or free text for status message displayed on the login page
     */
    submitBackendLogout: (statusMessage: string = '') => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const form = document.createElement('form');
        form.method = 'post';
        form.action = (apiUrl.endsWith('/') ? apiUrl.slice(0, -1) : apiUrl) + '/logout';
        if (statusMessage) {
            const input = document.createElement('input');
            input.name = 'status';
            input.value = statusMessage;
            form.appendChild(input);
        }
        document.body.appendChild(form);
        form.submit();
    },
};

export const useLogout = (onlyFESession = false) => {
    const logoutOnlineBooking = useOnlineBookingLogout();
    const logoutOrders = useOrdersLogout();

    return useCallback(async () => {
        // logout in third party apps (online booking)
        await logoutOnlineBooking();

        // logout in third party apps (orders)
        await logoutOrders();

        LogoutTools.clearBrowserStorage();
        if (!onlyFESession) {
            LogoutTools.submitBackendLogout();
        }
    }, [logoutOnlineBooking, logoutOrders, onlyFESession]);
};

export const useAuth = () => {
    const [accessToken, setToken] = useState(localStorage.getItem('access_token'));

    const logout = useLogout();

    const setAccessToken = useCallback(
        (newAccessToken) => {
            if (!newAccessToken) {
                logout();
            } else {
                localStorage.setItem('access_token', newAccessToken);
                setToken(newAccessToken);
            }
        },
        [logout]
    );

    return {
        isLoggedIn: !!accessToken,
        setAccessToken,
    };
};
