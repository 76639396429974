import React, { Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { usePreloadedQuery, useQueryLoader } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useTranslation } from 'react-i18next';
import AppLoadingIndicator from '../components/AppLoadingIndicator/AppLoadingIndicator';
import { permissions } from '../constants';
import { useLogout } from '../hooks/useAuth';

export const UserQuery = graphql`
    query UserContext_UserQuery {
        me {
            crm_id
            id
            company {
                uirr
                name
                street
                address_supplement
                zipcode
                country
                city
            }
            gender
            title
            title_custom
            phone {
                composed
                country
                dialing_code
                number
                extension
            }
            mobile {
                composed
                country
                dialing_code
                number
                extension
            }
            position
            email
            forename
            surname
            language
            permissions {
                permissionId
                name
                isEnabled
            }
            emailServices {
                emailServiceId
                isEnabled
            }
            consents {
                agree_to_data_privacy_statement
                receive_kombibrief
                receive_service_updates
                receive_event_invites
                receive_polling
            }
            unknownPublishedStoerer {
                internal_id
                type
                text_style
                text_headline
                text_intro
                text_content
                image_url
                image_link
                close_type
                button_type
            }
        }
    }
`;

const UserQueryLoader = ({ children }) => {
    const [userQueryRef, loadUserQuery] = useQueryLoader(UserQuery);

    const refreshUser = useCallback(() => {
        loadUserQuery({}, { fetchPolicy: 'store-and-network' });
    }, [loadUserQuery]);

    useEffect(() => {
        loadUserQuery();
    }, [loadUserQuery]);

    return (
        <Suspense fallback={<AppLoadingIndicator />}>
            {userQueryRef && (
                <ContextWrapper userQueryRef={userQueryRef} refreshUser={refreshUser}>
                    {children}
                </ContextWrapper>
            )}
        </Suspense>
    );
};

const ContextWrapper = ({ userQueryRef, refreshUser, children }) => {
    const { me } = usePreloadedQuery(UserQuery, userQueryRef);
    const { i18n } = useTranslation();
    const logout = useLogout();
    const [timestamp, setTimestamp] = useState(Date.now());

    useEffect(() => {
        const newLng = me.language === 'ENGLISH' ? 'en' : 'de';
        if (newLng !== i18n.language) {
            i18n.changeLanguage(newLng);
        }
    }, [i18n, me.language, i18n.language]);

    useEffect(() => {
        const interval = setInterval(() => {
            const latestActionExpired =
                window.sessionStorage.getItem('lastAction') * 1 < Date.now() - 4 * 60 * 60 * 1000;
            if (latestActionExpired) {
                logout();
            } else {
                refreshUser();
            }
            setTimestamp(Date.now());
        }, 60000);
        return () => clearInterval(interval);
    }, [refreshUser, logout]);

    const isRestricted = useMemo(
        () =>
            !me.permissions.find(({ permissionId }) => permissionId === permissions.VERIFIED)
                ?.isEnabled,
        [me]
    );

    const isSupervisor = useMemo(
        () =>
            me.permissions.find(({ permissionId }) => permissionId === permissions.SUPERVISOR)
                ?.isEnabled,
        [me]
    );

    const contextValue = {
        me: {
            ...me,
            avatarUrl: '/avatar.svg',
        },
        avatarUrl: '/avatar.svg',
        isRestricted,
        isSupervisor,
        refreshUser,
        timestamp,
    };

    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export const UserContext = React.createContext({
    me: {},
    avatarUrl: '',
    isRestricted: true,
    refreshUser: () => undefined,
    timestamp: null,
});

export const useUserContext = () => {
    return useContext(UserContext);
};

export default UserQueryLoader;
