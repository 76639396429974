import graphql from 'babel-plugin-relay/macro';
import { useCallback } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { t } from 'i18next';

export const DownloadsQuery = graphql`
    query useDownloadsQuery($tags: [String!], $first: Int = 99) {
        downloads(tags: $tags, first: $first) {
            edges {
                node {
                    id
                    name
                    url
                    type
                    booklet_id
                    file_name
                    file_type
                    file_size
                    updated_at
                    updated_at_diff
                    main_category {
                        id
                        name
                    }
                    categoryAssignments(first: $first) {
                        edges {
                            node {
                                category {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

function useDownloads(tags = null, first = 1000) {
    const data = useLazyLoadQuery(DownloadsQuery, { tags, first });
    return data.downloads.edges.map(({ node: download }) => downloadMapper(download));
}

export const downloadMapper = (download) => ({
    id: download.id,
    name: download.name,
    url: download.url,
    fileExtension: download.file_name?.split('.').pop().toUpperCase() ?? '',
    booklet_id: download.booklet_id,
    type: download.type,
    fileSize: download.type === 'booklet' ? t('downloads.fileSizeForBooklet') : download.file_size,
    fileType: download.file_type,
    updatedAt: download.updated_at,
    updatedAtDiff: download.updated_at_diff,
    mainCategory: download.main_category,
    categories: download.categoryAssignments.edges
        .filter(({ node }) => !!node.category)
        .map(({ node }) => {
            return {
                id: node.category.id,
                name: node.category.name,
            };
        }),
});

export function useTransforms() {
    const bytesToString = useCallback((size) => {
        const i = Math.floor(Math.log(size) / Math.log(1024));
        const unit = ['B', 'kB', 'MB', 'GB', 'TB'][i];
        const readableSize = (size / Math.pow(1024, i)).toFixed(1) * 1;
        return `${readableSize} ${unit}`;
    }, []);

    return {
        bytesToString,
    };
}

export default useDownloads;
