import graphql from 'babel-plugin-relay/macro';
import { useState, useEffect } from 'react';
import { useMutation } from 'react-relay';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button, { ButtonStyle } from '../Button/Button';
import LanguageSelect from '../Form/Select/LanguageSelect';
import Alert, { AlertType } from '../Alert/Alert';

import styles from './ChangeLanguageForm.module.scss';

export const ChangeLanguageMutation = graphql`
    mutation ChangeLanguageFormMutation($input: UpdateProfileLanguageInput!) {
        updateProfileLanguage(input: $input) {
            id
            language
        }
    }
`;

const ChangeLanguageForm = ({ language, onChange }) => {
    const [isReadonly, setIsReadonly] = useState(true);
    const [newLanguage, setNewLanguage] = useState(language);
    const [languageError, setLanguageError] = useState('');
    const [error, setError] = useState('');
    const [updateLanguage, isLoading] = useMutation(ChangeLanguageMutation);
    const { t } = useTranslation();

    useEffect(() => setNewLanguage(language), [language]);

    const submitForm = () => {
        setError('');
        setLanguageError('');
        updateLanguage({
            variables: {
                input: { language: newLanguage },
            },
            onError: (networkError) => {
                const message =
                    networkError instanceof Error
                        ? networkError.message
                        : t('generics.unexpectedError');
                setError(message);
            },
            onCompleted: (_, errors) => {
                if (errors?.length) {
                    const [mutationError] = errors;
                    if (mutationError.extensions.validation) {
                        setLanguageError(
                            mutationError.extensions.validation['input.language']?.[0] ?? ''
                        );
                    } else {
                        setError(mutationError.message);
                    }
                } else {
                    if (newLanguage !== language) {
                        onChange();
                    }
                    setIsReadonly(true);
                }
            },
        });
    };

    const resetForm = () => {
        setNewLanguage(language);
    };

    return (
        <div className={styles.ChangeLanguageForm} data-testid='ChangeLanguageForm'>
            <form>
                <LanguageSelect
                    value={newLanguage}
                    onChange={(value) => setNewLanguage(value)}
                    readonly={isReadonly}
                    error={languageError}
                    required
                />
            </form>
            {!isReadonly && (
                <Alert type={AlertType.WARNING} className={styles.AlertBox}>
                    <p className={styles.ChangeAlert}>
                        <strong>{t('generics.attention', { lng: 'de' })}</strong>:{' '}
                        {t('profile.changeLanguageInfo', { lng: 'de' })}
                    </p>
                    <p className={styles.ChangeAlert}>
                        <strong>{t('generics.attention', { lng: 'en' })}</strong>:{' '}
                        {t('profile.changeLanguageInfo', { lng: 'en' })}
                    </p>
                </Alert>
            )}
            {error && <Alert type={AlertType.ERROR}>{error}</Alert>}
            <div className={styles.FormButtons}>
                {isReadonly ? (
                    <Button
                        style={ButtonStyle.SECONDARY}
                        text={t('profile.modifyLanguage')}
                        onClick={() => setIsReadonly(false)}
                        block
                    />
                ) : (
                    <>
                        <Button
                            style={ButtonStyle.PRIMARY}
                            text={t('profile.saveLanguage')}
                            onClick={() => submitForm()}
                            disabled={isLoading}
                            block
                        />
                        <Button
                            style={ButtonStyle.SECONDARY}
                            text={t('generics.cancelButton')}
                            onClick={() => {
                                resetForm();
                                setIsReadonly(true);
                            }}
                            disabled={isLoading}
                            block
                        />
                    </>
                )}
            </div>
        </div>
    );
};

ChangeLanguageForm.propTypes = {
    language: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default ChangeLanguageForm;
