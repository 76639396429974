import React, { useEffect, useState } from 'react';
import { AuthError } from '../Auth/Error';
import styles from './SessionErrorBoundary.module.scss';
import { useTranslation } from 'react-i18next';
import { LogoutTools } from '../../hooks/useAuth';

export const SessionErrorBoundaryContent = () => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);

    const onForceLogout = () => {
        LogoutTools.clearBrowserStorage();
        LogoutTools.submitBackendLogout('expired_session');
    };

    useEffect(() => {
        const t = setTimeout(() => setVisible(true), 3000);
        onForceLogout();
        return () => clearTimeout(t);
    });

    return !visible ? (
        <></>
    ) : (
        <div className={styles.container}>
            <div className={styles.SessionErrorBoundary}>
                {t('errors.sessionErrorBoundary.message')}
            </div>
            <button onClick={onForceLogout} className={styles.Link}>
                {t('errors.sessionErrorBoundary.action')}
            </button>
        </div>
    );
};

export class SessionErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        if (error instanceof AuthError) {
            return { hasError: true };
        }

        throw error;
    }

    componentDidCatch(error, info) {
        console.log('Auth token has expired');
    }

    render() {
        if (this.state.hasError) {
            return <SessionErrorBoundaryContent />;
        }
        return this.props.children;
    }
}
